export const UI_PAGE_WIDGET_BASE_FRAGMENT = `
  fragment uiPageWidgetBaseFragment on UiPageWidget {
    id
    uid
    type
    active
    payload
    displaySequence
    parentWidget {
        id
        uid
        type
        active
    }
    page {
        id
    }
  }
`;
